import React from "react";

import { StyledIconLink } from "./styles";

import {
  AiFillHome,
  AiFillLinkedin,
  AiFillGithub,
  AiFillTwitterSquare,
} from "react-icons/ai";
import {
  MdEmail,
  MdGroups,
  MdPictureAsPdf
} from "react-icons/md";
import {
  BsLink45Deg,
} from "react-icons/bs";
import {
  FaOrcid,
  FaUniversity,
  FaUnlockAlt,
  FaMastodon
} from "react-icons/fa";
import {
  SiGooglescholar,
  SiDblp,
} from "react-icons/si";

const IconMaterial = ({ material }) => {
  switch (material[0]) {
    case "Homepage": 
      return (
        <StyledIconLink href = {material[1]}>
        <AiFillHome     size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "ORCID": 
      return (
        <StyledIconLink href = {material[1]}>
        <FaOrcid        size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Google Scholar": 
      return (
        <StyledIconLink  href = {material[1]}>
        <SiGooglescholar size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "LinkedIn": 
      return (
        <StyledIconLink href = {material[1]}>
        <AiFillLinkedin size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "GitHub": 
      return (
        <StyledIconLink href = {material[1]}>
        <AiFillGithub   size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Lab GitHub": 
      return (
        <StyledIconLink href = {material[1]}>
        <AiFillGithub   size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Email": 
      return (
        <StyledIconLink href = {"mailto:" + material[1]}>
        <MdEmail        size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Mastodon": 
      return (
        <StyledIconLink href = {material[1]}>
        <FaMastodon     size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Twitter": 
      return (
        <StyledIconLink      href = {material[1]}>
        <AiFillTwitterSquare size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "Group Page": 
      return (
        <StyledIconLink href = {material[1]}>
        <MdGroups       size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "College Bio Page": 
      return (
        <StyledIconLink href = {material[1]}>
        <FaUniversity   size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "OSF": 
      return (
        <StyledIconLink href = {material[1]}>
        <FaUnlockAlt    size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "DBLP": 
      return (
        <StyledIconLink href = {material[1]}>
        <SiDblp         size = {20} /> {material[0]}
        </StyledIconLink>
      );
    case "CV": 
      return (
        <StyledIconLink href = {material[1]}>
        <MdPictureAsPdf size = {20} /> {material[0]}
        </StyledIconLink>
      );
    default: 
      return (
        <StyledIconLink href = {material[1]}>
        <BsLink45Deg    size = {20} /> {material[0]}
        </StyledIconLink>
      );
  }
};

export default IconMaterial;
