import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { StyledTitle } from "../../styles";
import { Col } from "react-bootstrap";
import { PubRow, StyledImage, ProTitle } from "./styles";
import Materials from "../../components/Materials";
import Authors from "../../components/Authors";
import Project from "./Project";

const ProjectTeaser = ({ project, people }) => {
  let projectLoc = project.link ? project.link : "/projects/" + project.urlId;
  return (
    <PubRow key={project.urlId}>
      <Col xs={6} sm={3} md={2}>
        <a href={process.env.PUBLIC_URL + projectLoc}>
          {project.teaserImg ? (
            <StyledImage
              src={process.env.PUBLIC_URL + "/Image/paper/teaser/" + project.teaserImg}
              thumbnail
            />
          ) : (
            <StyledImage
              src={process.env.PUBLIC_URL + "/Image/default4-3.svg"}
              thumbnail
            />
          )}
        </a>
      </Col>
      <Col xs={12} sm={9} md={8}>
        <ProTitle href={process.env.PUBLIC_URL + projectLoc}> {project.title}</ProTitle>
        <p>
          <Authors names={project.authors} people={people} />
        </p>
        <p>{project.shortDesc}</p>
        <p>
          <Materials materials={project.materials} />
        </p>
      </Col>
    </PubRow>
  );
};

const Projects = ({ papers, people, projects }) => {
  let { path } = useRouteMatch();
  return (
      <Switch>
        <Route exact path={path}>
          <StyledTitle id="projects"> Projects</StyledTitle>
          {projects.map((pro, index) => (
            <ProjectTeaser key={index} project={pro} people={people} />
          ))}
        </Route>
        <Route path={`${path}/:projectId`}>
          <Project projects={projects} people={people} papers={papers} />
        </Route>
      </Switch>
  );
};

export default Projects;
