import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import Blog from "./Blog";
import Publications from "./Publications";
import People from "./People";
import Teaching from "./Teaching";
import Funding from "./Funding";
import Culture from "./Culture";
import Layout from "../components/Layout";
import Recruitment from "./Recruitment";
import Archive from "./Archive";
import NotFound from "./NotFound";

import { Helmet } from "react-helmet";

const Pages = ({
  home,
  papers,
  people,
  projects,
  blog,
  teaching,
  funding,
  culture,
}) => {
  let individuals = {};

  const [BibTexFile, setBibTexFile] = useState({});

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/bibtex/bibtex.bib")
      .then((r) => {
        if (!r.ok) {
          throw Error(r.statusText);
        }
        return r.text();
      })
      .then((text) => {
        //https://regex101.com/r/b0KMlF/1
        let regex = /@([\s\S]*?)^}/gm;
        let bibtexs = text.match(regex);
        let names = text.match(/@\S+{([\s\S]*?),/gm);
        let name2bib = {};
        for (let i = 0; i < bibtexs.length; i++) {
          let name = names[i].split("{")[1].replace(",", "").trim();
          name2bib[name] = bibtexs[i];
        }
        setBibTexFile(name2bib);
      });
  }, []);

  blog.posts.forEach((post) => (post.slug = `${post.date}-${post.urlId}`));
  blog.posts.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

  for (const [, persons] of Object.entries(people)) {
    for (const individual of persons) {
      individuals[individual.name] = individual;
      if (individual.alias) {
        for (const name of individual.alias) {
          individuals[name] = individual;
        }
      }
    }
  }

  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Helmet>
            <title>Khoury Vis Lab, Northeastern University</title>
            <meta
              name="description"
              content="Front page Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Home data={home} papers={papers} people={individuals} />
        </Route>
        <Route path="/pubs">
          <Helmet>
            <title>
              Publications - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Publications of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Publications
            papers={papers}
            people={individuals}
            BibTexFile={BibTexFile}
          />
        </Route>
        <Route path="/people">
          <Helmet>
            <title>
              People - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="People of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <People
            people={people}
            peopleDict={individuals}
            papers={papers.publications}
          />
        </Route>
        <Route path="/open-positions">
          <Helmet>
            <title>
              Open Positions - Khoury Vis Lab, Northeastern
              University
            </title>
            <meta
              name="description"
              content="Open Positions of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Recruitment />
        </Route>
        <Route path="/teaching">
          <Helmet>
            <title>
              Teaching - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Courses taught by members of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Teaching teaching={teaching} people={individuals} />
        </Route>
        <Route path="/funding">
          <Helmet>
            <title>
              Funding - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Funding of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Funding funding={funding} people={individuals} />
        </Route>
        <Route path="/culture">
          <Helmet>
            <title>
              Culture - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Lab culture of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Culture culture={culture} />
        </Route>
        <Route path="/projects">
          <Helmet>
            <title>
              Projects - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Projects of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Projects
            projects={projects.projects}
            people={individuals}
            papers={papers.publications}
          />
        </Route>
        <Route path="/blog">
          <Helmet>
            <title>
              Blog - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="Blog of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Blog posts={blog.posts} people={individuals} />
        </Route>
        <Route path="/archive">
          <Helmet>
            <title>
              News - Khoury Vis Lab, Northeastern University
            </title>
            <meta
              name="description"
              content="News of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <Archive data={home} />
        </Route>
        <Route path="*">
          <Helmet>
            <title>Khoury Vis Lab, Northeastern University</title>
            <meta
              name="description"
              content="Front page of the Khoury Vis Lab, Northeastern University"
            />
          </Helmet>
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Pages;
