import React from "react";
import PersonLink from "../../components/PersonLink";

const Authors = ({ names, people }) => {
  return names.map((name, index) => (
    <React.Fragment key={index}>
      <PersonLink name={name} people={people} />
      {index < names.length - 2
        ? ", "
        : index < names.length - 1 && names.length === 2
        ? " and "
        : index < names.length - 1
        ? ", and "
        : ""}
    </React.Fragment>
  ));
};

export default Authors;
