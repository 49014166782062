import React from "react";
import PersonLink from "../../components/PersonLink";
import { Row, Container } from "react-bootstrap";
import { SemesterName, CourseName } from "./styles";
import { StyledTitle } from "../../styles";

const Semester = ({ semester, people }) => {
  return (
    <>
      <Row>
        <SemesterName>{semester[0]}</SemesterName>
      </Row>
      {semester[1].map((course, index) => (
        <Row key={index}>
          <CourseName>
            {course.courseLink ? (
              <a href={course.courseLink}>{course.courseName}</a>
            ) : (
              course.courseName
            )}{" "}
            {course.instructor !== "" ? "—" : ""}{" "}
            <PersonLink name={course.instructor} people={people} />
          </CourseName>
        </Row>
      ))}
    </>
  );
};

const Teaching = ({ teaching, people }) => {
  return Object.entries(teaching).map((pair) => (
    <Container key={pair[0]}>
      <hr />
      <Row>
        <StyledTitle>{pair[0]}</StyledTitle>
      </Row>
      {pair[1].map((semesters) =>
        Object.entries(semesters).map((semester, index) => (
          <Semester key={index} semester={semester} people={people} />
        ))
      )}
    </Container>
  ));
};

export default Teaching;
