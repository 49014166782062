import React from "react";

import { useParams } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
import {
  PersonName,
  PersonTitle,
  PersonDescription,
  SectionTitle,
  SectionSubTitle,
} from "./styles";
import Citation from "../../components/Citation";
import NotFound from "../../pages/NotFound";
import { Helmet } from "react-helmet";
import IconMaterial from "./IconMaterial";
import NameCards from "../../components/NameCards";

const Person = ({ advisorToPeople, peopleDict, papers }) => {
  let { personId } = useParams();

  let person = Object.entries(peopleDict).find(
    (nameInfo) => nameInfo[1].name.trim().replaceAll(" ", "-") === personId
  );
  if (person) {
    person = person[1];
  } else {
    return <NotFound />;
  }

  let personPub = papers.filter((paper) => {
    if (paper.authors.includes(person.name)) {
      return true;
    } else if (person.alias) {
      for (const name of person.alias) {
        if (paper.authors.includes(name)) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  });

  personPub.sort(function (a, b) {
    return b.year - a.year;
  });

  const ShowHeaderYear = ({ pub, prevPub }) => {
    if (prevPub === undefined || prevPub.year !== pub.year) {
      return <SectionSubTitle>{pub.year}</SectionSubTitle>;
    } else {
      return <></>;
    }
  };

  let listMaterials = Object.entries(person.materials);
  listMaterials = listMaterials.filter((pair) => pair[1]);

  const people = advisorToPeople[person.name] ?? null;
  const showAdvisees =
    people && Object.values(people).some((x) => x.length > 0);

  return (
    <>
      <Helmet>
        <title>
          {person.name} - Khoury Vis Lab, Northeastern
          University
        </title>
        <meta
          name="description"
          content={
            person.name +
            " - Khoury Vis Lab, Northeastern University"
          }
        />
      </Helmet>

      <Row>
        <Col xs={10} sm={5} lg={"auto"}>
          {person.image && (
            <Image
              src={process.env.PUBLIC_URL + "/Image/people/" + person.image}
              width={255}
              rounded
            ></Image>
          )}
        </Col>
        <Col>
          <PersonName style={{ fontSize: "32px" }}>{person.name}</PersonName>
          <PersonTitle style={{ fontSize: "18px" }}>{person.title}</PersonTitle>
          <PersonDescription>{person.description}</PersonDescription>
          {listMaterials.map((pair, index) => (
            <Row key={index}>
              <IconMaterial material={pair} />
            </Row>
          ))}
        </Col>
      </Row>

      {showAdvisees && (
        <div>
          <SectionTitle>Advisees and Associates</SectionTitle>
          {Object.entries(people)
            .filter(([_, persons]) => persons.length > 0)
            .map(([role, persons]) => (
              <React.Fragment key={role}>
                <SectionSubTitle>{role}</SectionSubTitle>
                <NameCards
                  names={persons}
                  people={peopleDict}
                  showImage={true}
                />
              </React.Fragment>
            ))}
        </div>
      )}

      <div>
        {personPub.length > 0 && <SectionTitle>Publications</SectionTitle>}
        {personPub.map((pub, index) => (
          <React.Fragment key={index}>
            <ShowHeaderYear pub={pub} prevPub={personPub[index - 1]} />
            <Citation pub={pub} people={peopleDict} />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default Person;
